import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { showInfo, showDanger } from "../../actions/notification";
import { tryUserAuth } from "../../actions/user";

import LoaderRoller from "../../components/loader/LoaderRoller";

const initialState = {
  token: "",
  expires: "",
  refresh: "",
};

class Authorization extends Component {
  state = { ...initialState };
  componentDidMount = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get("token");
    const expires = queryParams.get("expires");
    const refresh = queryParams.get("refresh");
    const expiration = new Date(expires);
    const dateNow = new Date();

    if (token && expires && refresh) {
      if (expiration < dateNow) {
        // Token have expired
        this.props.history.push("/");
        this.props.showDanger({
          header: "Watt Maestro",
          body: "Sorry, your token have expired",
        });
        return;
      }
      //  Try Authorization
      this.props.tryUserAuth({
        token,
        expires,
        refresh,
      });
    } else {
      // redirect to login in case token, expiration or refresh token are missing
      this.props.history.push("/");
    }
  };

  render() {
    return <LoaderRoller />;
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    showInfo,
    showDanger,
    tryUserAuth,
  })(Authorization)
);

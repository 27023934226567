import React, { Component } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";

class EquipmentStatus extends Component {
  state = {
    projectData: [],
  };
  render() {
    let equipmentData = [];
    if (
      Array.isArray(this.props.equipmentStatusData) &&
      this.props.equipmentStatusData.length > 0
    ) {
      this.props.equipmentStatusData.forEach((equipmentStatus) => {
        if (equipmentStatus.physicalDevices) {
          equipmentStatus.physicalDevices.forEach((physicalDevice) => {
            if (
              physicalDevice.id_parent !== null &&
              physicalDevice.deviceProfile !== null
            ) {
              const deviceProfile = physicalDevice.deviceProfile;
              equipmentData.push({
                type: deviceProfile.equipmentLabel ?? "Unknown Type",
                brand:
                  (deviceProfile.manufacturer ?? "Unknown Manufacturer") +
                  " " +
                  (deviceProfile.model ?? "Unknown Model"),
                status:
                  physicalDevice.is_connected === 1 ? "Online" : "Offline",
              });
            }
          });
        }
      });
    }

    return (
      <div style={{ width: "100%" }}>
        <div className="equipmentStatusHeading">
          {" "}
          Equipment Status{" "}
          <Link to={`/site/monitor/${this.props.groupId}?`}>
            {" "}
            <FontAwesomeIcon
              className="svg-icons-sm linkIcon"
              data-toggle="tooltip"
              data-placement="top"
              icon={faArrowRight}
            />
          </Link>{" "}
        </div>

        <div className="memberHeadingAlign">
          <table className="equipmentTable">
            <thead>
              <tr className="equipmentStatusTextRow">
                <th className="headerTextRow col-md-1"></th>
                <th className="headerTextRow col-md-3 p-2">Type</th>
                <th className="headerTextRow col-md-5 p-2">Brand</th>
                <th className="headerTextRow col-md-3 p-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {equipmentData.map((equipment, index) => (
                <tr
                  className={`equipmentRow ${
                    index === equipmentData.length - 1 ? "lastRow" : ""
                  }`}
                  key={index}
                >
                  <td className="col-md-1"></td>
                  <td className="col-md-3 p-2">
                    {equipment.type === "Solar" ? "Inverter" : equipment.type}
                  </td>
                  <td className="col-md-5 p-2">{equipment.brand}</td>
                  <td className="col-md-3 statusTextRow">
                    <div
                      className={
                        equipment.status.toLowerCase() === "online"
                          ? "online"
                          : "offline"
                      }
                    >
                      {equipment.status}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EquipmentStatus;

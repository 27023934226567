import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "./styles.css";
import { getrequestMeasurement } from "../../actions/list-view";
import { styled } from "@mui/material/styles";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";

class LastSeenStatus extends Component {
  state = {
    lastSeenUpdate: false,
    alertVisible: false,
    isButtonDisabled: false,
  };
  buttonRef = createRef();
  handleButtonClick = async () => {
    const formInput = { ...this.state.formInput };

    formInput.lastSeenUpdate = true;
    this.setState({ formInput });

    const data = this.props.equipmentStatusData;
    const filterOptions = this.props.filterOptions;

    let id_physical_device;

    if (data && data.length > 0) {
      const firstPhysicalDevice = data[0]?.physicalDevices;

      if (firstPhysicalDevice) {
        const deviceWithNullParent = firstPhysicalDevice.find(
          (device) => device.id_parent === null
        );

        if (deviceWithNullParent) {
          id_physical_device = deviceWithNullParent.id_physical_device;
        }
      }
    }

    this.setState((prevState) => ({
      isButtonDisabled: true,
      formInput: {
        ...prevState.formInput,
      },
    }));

    // const button = document.querySelector(".lastSeen");
    const button = this.buttonRef.current;
    if (button) {
      button.style.background = "#A5A5A7";
    }
    try {
      const status = await this.props.getrequestMeasurement(
        id_physical_device,
        filterOptions
      );
      this.setState({
        status: status,
      });
    } catch (error) {
      console.error("Error in handleButtonClick:", error);
      console.error("Unexpected error:", error);
    } finally {
      this.alertTimeout = setTimeout(() => {
        this.setState((prevState) => ({
          isButtonDisabled: false,
          alertVisible: true,
          formInput: {
            ...prevState.formInput,
          },
        }));
        button.style.background = "#25DBA7";
      }, 30000);
    }
  };

  handleToClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.lastSeenUpdate = false;
    this.setState({ formInput, alertVisible: false });
  };

  calculateTimeAgo = (lastDataTimestamp) => {
    if (isNaN(lastDataTimestamp)) {
      return "Invalid date";
    }

    const currentTimestamp = Date.now();
    const duration = moment.duration(currentTimestamp - lastDataTimestamp);

    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths());
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds());

    if (years > 0) {
      return `${years} ${years === 1 ? "YEAR" : "YEARS"} AGO`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "MONTH" : "MONTHS"} AGO`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? "DAY" : "DAYS"} AGO`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? "HR" : "HRS"} AGO`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "MIN" : "MINS"} AGO`;
    } else {
      return `${seconds} ${seconds === 1 ? "SEC" : "SECS"} AGO`;
    }
  };

  render() {
    const { alertVisible, isButtonDisabled, status } = this.state;
    const data = this.props.equipmentStatusData;
    const lastSeenVal = data[0]?.meta?.last_data_at;
    const BlackOutlinedErrorAlert = styled(Alert)({});
    const { isMobile, isMonitor } = this.props;
    return (
      <div style={{ marginRight: "0px", marginLeft: "auto" }}>
        {isMobile ? (
          <div
            className={`lastseen-mob ${isButtonDisabled ? "spinning" : ""}`}
            onClick={this.handleButtonClick}
            ref={this.buttonRef}
            disabled={isButtonDisabled}
            style={{
              backgroundColor: isButtonDisabled ? "gray" : "#25dba7",
            }}
          >
            <FontAwesomeIcon
              className="fa-lg lastseen-btn-color"
              icon={faArrowsRotate}
            />
          </div>
        ) : (
          <>
            <button
              className={`lastSeen ${isButtonDisabled ? "spinning" : ""}`}
              onClick={this.handleButtonClick}
              disabled={isButtonDisabled}
            >
              LAST SEEN: {this.calculateTimeAgo(lastSeenVal)} &nbsp;
              <FontAwesomeIcon icon={faSyncAlt} />
            </button>
            <div>
              {status !== undefined && alertVisible && (
                <div className="alertLastSeenDialog">
                  <BlackOutlinedErrorAlert
                    className={`${
                      status === 200 ? "success-dialog" : "error-dialog"
                    }`}
                    style={{
                      backgroundColor: status === 200 ? "#84CE66" : "#FF5D5D",
                    }}
                    severity={status === 200 ? "success" : "error"}
                    onClose={this.handleToClose}
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                  >
                    <AlertTitle className="alert-title">
                      {status === 200
                        ? "Site Refresh Initiated"
                        : "Update Error"}
                    </AlertTitle>
                    {status === 200 ? (
                      <span className="alert-des">
                        The gateway is working on fetching the latest data from
                        the equipment and will refresh values in 30 seconds.
                      </span>
                    ) : (
                      <span className="alert-des">
                        An unexpected error has occurred, and the gateway is not
                        reachable at the moment. Please check if the gateway is
                        powered and connected.
                      </span>
                    )}
                  </BlackOutlinedErrorAlert>
                </div>
              )}
            </div>
          </>
        )}

        {isMobile && alertVisible && (
          <div
            className="mobileAlert"
            style={{
              bottom: isMonitor === "Monitor" ? "38vh" : "40vh",
              left: isMonitor === "Monitor" ? "-22%" : "17%",
            }}
          >
            <BlackOutlinedErrorAlert
              className={`${
                status === 200 ? "success-dialog" : "error-dialog"
              }`}
              style={{
                backgroundColor: status === 200 ? "#84CE66" : "#FF5D5D",
              }}
              severity={status === 200 ? "success" : "error"}
              onClose={this.handleToClose}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
            >
              <AlertTitle className="alert-title">
                {status === 200 ? "Site Refresh Initiated" : "Update Error"}
              </AlertTitle>
              {status === 200 ? (
                <span className="alert-des">
                  The gateway is working on fetching the latest data from the
                  equipment and will refresh values in 30 seconds.
                </span>
              ) : (
                <span className="alert-des">
                  An unexpected error has occurred, and the gateway is not
                  reachable at the moment. Please check if the gateway is
                  powered and connected.
                </span>
              )}
            </BlackOutlinedErrorAlert>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lastSeenDataResponse: state.listViewReducer?.lastSeenDataResponse,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getrequestMeasurement,
  })(LastSeenStatus)
);

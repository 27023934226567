import React, { Component, Fragment } from "react";
import CONFIG from "../../config";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import "../login/style.css";
import "./style.css";
import { createRegister } from "../../actions/user";
import Success from "./Success";
import FormField from "../shared/components/form/FormField";
import AlternateEmailIcon from "mdi-react/AlternateEmailIcon";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountOr,
  AccountTitle,
  AccountWrap,
} from "../shared/components/account/AccountElements";

const { loginUrl, clientId, clientSecret } = CONFIG;

class Register extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount = () => {};

  handleLabelChange = (event) => {
    const formInput = { ...this.state.formInput };
    formInput.emailValue = event.target.value.toLowerCase();
    this.setState({ formInput });
  };

  handleRegister = () => {
    const formInput = { ...this.state.formInput };
    const emailValid =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailValid.test(formInput.emailValue)) {
      formInput.emailValid = true;
    } else {
      formInput.emailValid = false;
      this.props.createRegister(formInput.emailValue, clientId, clientSecret);
    }
    this.setState({ formInput });
  };

  onSubmit = ({ name, password }) => {};
  render() {
    const { formInput } = this.state;

    return (
      <Fragment>
        {this.props.user?.RegisterDataResult?.status === 200 && (
          <div>
            <Route path="/register/success" exact component={Success} />
          </div>
        )}
        <div className="loginImgDiv">
          <div className="wattTitle">WattMaestro</div>
        </div>
        <div>
          <AccountWrap>
            <AccountContent>
              <AccountCard>
                <AccountHead>
                  <AccountTitle>
                    Welcome to
                    <AccountLogo> WattMaestro</AccountLogo>
                  </AccountTitle>
                  <h4 className="subhead">Create an account!</h4>
                </AccountHead>

                <div>
                  <div className="email-Text">E-mail</div>
                  <div style={{ display: "flex" }}>
                    {" "}
                    <AlternateEmailIcon className="atIcon-WattMaestro"  style={{marginTop:"7px"}}/>
                    <input
                      name="email"
                      component={FormField}
                      type="email"
                      placeholder="mymail@mail.com"
                      className="input-wattMastro"
                      onChange={this.handleLabelChange}
                      style={{marginTop:"7px"}}
                    />
                  </div>
                </div>

                <AccountOr></AccountOr>
                <div style={{ display: "flex" }}>
                  {" "}
                  <button
                    onClick={this.handleRegister}
                    rel="noopener noreferrer"
                    className="register-signUp-btn-watt"
                  >
                    <div className="signIn-Watt">Sign up</div>
                  </button>
                </div>
                <div className="link-Div">
                  <div className="alreadyMsg"> Already have an account?</div>
                  <div>
                    {" "}
                    <a
                      href={decodeURIComponent(
                        `${loginUrl}?ClientId=${clientId}&ClientSecret=${clientSecret}`
                      )}
                      rel="noopener noreferrer"
                      className="login-btn-watt"
                    >
                      <div className="login-register-Watt">Login</div>
                    </a>
                  </div>
                </div>
                <div
                  className={
                    formInput.emailValid ? "showErrorMsgMode" : "hideMode"
                  }
                >
                  <p className="emailValidation">
                    {" "}
                    Email address is not valid or can't be empty.
                  </p>
                </div>
              </AccountCard>
            </AccountContent>
          </AccountWrap>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    RegisterDataResult: state.userReducer?.RegisterDataResult,
    RegisterFailedDataResult: state.userReducer?.RegisterFailedDataResult,
  };
}

export default withRouter(
  connect(mapStateToProps, { createRegister })(Register)
);

import globalFunctions from "../helpers/functions";
const { uniqueId } = globalFunctions;

const initialState = [];

const infoColor = "#003DFF";
const warningColor = "#F26D00";
const dangerColor = "#EC0000";

export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case "CLEAR_ALERTS": {
      return [...initialState];
    }
    case "ADD_WARNING": {
      return [
        {
          uniqueId: uniqueId(),
          timestamp: Date.now(),
          header: action.data.header || "WattMaestro",
          body: action.data.body,
          fillColor: warningColor,
        },
        ...state,
      ];
    }
    case "ADD_DANGER": {
      return [
        {
          uniqueId: uniqueId(),
          timestamp: Date.now(),
          header: action.data.header || "WattMaestro",
          body: action.data.body,
          fillColor: dangerColor,
        },
        ...state,
      ];
    }
    case "ADD_INFO": {
      return [
        {
          uniqueId: uniqueId(),
          timestamp: Date.now(),
          header: action.data.header || "WattMaestro",
          body: action.data.body,
          fillColor: infoColor,
        },
        ...state,
      ];
    }
    case "CLEAR_ONE_NOTIFICATION": {
      return [
        ...state.filter((notification) => notification.uniqueId !== action.id),
      ];
    }
    default: {
      return state;
    }
  }
}

// BottomNavigation.js

import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse as fahousesolid,
  faSquarePollVertical as faSquarePollVerticalsolid,
  faMap,
  faHouse,
  faSquarePollVertical,
  faGear,
} from "@fortawesome/pro-light-svg-icons";
import "../style.css";

const BottomNavigation = ({ groupId, handleToRedirectSiteOverView }) => {
  const [activeItem, setActiveItem] = useState(null);
  const location = useLocation();

  const menuItems = [
    {
      label: "Home",
      icon: faHouse,
      //   iconsolid: fahousesolid,
      route: `/site/home/${groupId}`,
    },
    {
      label: "Monitor",
      icon: faSquarePollVertical,
      //   iconsolid: faSquarePollVerticalsolid,
      route: `/site/monitor/${groupId}`,
    },
    {
      label: "Settings",
      icon: faGear,
      route: `/site/user/${groupId}`,
    },
  ];

  const handleItemClick = (index, route) => {
    setActiveItem(index);
    // Redirect if handleToRedirectSiteOverView is provided
    if (route === "/") {
      handleToRedirectSiteOverView();
    }
  };

  return (
    <div className="bottom-navigation">
      <div
        onClick={() => handleItemClick(-1, "/")}
        className={`bottom-nav-link ${
          activeItem === -1 ? "bottom-active" : ""
        }`}
      >
        <FontAwesomeIcon icon={faMap} className="bottom-nav-icon fa-lg" />
      </div>
      {menuItems.map((item, index) => (
        <Link
          key={index}
          to={item.route}
          className={`bottom-nav-link ${
            location.pathname === item.route ? "bottom-active" : ""
          }`}
          onClick={() => handleItemClick(index, item.route)}
        >
          <FontAwesomeIcon icon={item.icon} className="bottom-nav-icon fa-lg" />
        </Link>
      ))}
    </div>
  );
};

export default BottomNavigation;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAllSubtypes } from "../../../actions/list-view";
import MenuItems from "./MenuItems";
import Success from "../../../views/register/Success";
import Failed from "../../../views/register/Failed";

class MainMenu extends Component {
  componentDidMount = () => {};

  render() {
    return (
      <Fragment>
        {this.props.userDetails?.RegisterDataResult?.status === 200 ? (
          <div>
            <Success />
          </div>
        ) : this.props.userDetails?.RegisterDataResult?.status === "failed" ||
          this.props.userDetails?.RegisterFailedDataResult === "failed" ? (
          <div>
            <Failed />
          </div>
        ) : (
          <div className="fixed-top min-vh-100 main-menu">
            <MenuItems
              groupId={this.props.groupId}
              clearProject={this.props.clearProject}
              projectLabel={this.props.projectLabel}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    subtypes: state.listViewReducer?.subtypes,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllSubtypes })(MainMenu)
);

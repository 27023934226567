import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import {
  getMemberDetailsForSite,
  clearProjectNameData,
  clearProjectMemberData,
  clearNoInternet,
} from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import UserMember from "../userMember/userMember";
import { renameSiteName } from "../../actions/list-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { styled } from "@mui/material/styles";

class user extends Component {
  state = {
    formInput: {
      siteNameData: "",
      newSiteName: false,
      isButtonDisabled: false,
      siteNameError: false,
      siteNameValue: "",
      noInternetView: true,
    },
  };
  componentDidMount = () => {
    const devUrls = [
      "https://evalan-dev-wattmaestro-frontend.azurewebsites.net/",
    ];
    const currentUrl = window.location.href;
    if (devUrls.some((devUrl) => currentUrl.startsWith(devUrl))) {
      document.title = "🚧 WattMaestro - Settings";
    } else {
      document.title = "WattMaestro - Settings";
    }

    let { groupId } = this.props.match.params;
    this.props.clearProjectMemberData();
    this.props.getMemberDetailsForSite(groupId);
  };

  handleSiteNameChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };

    if (input.value.length > 60) {
      formInput.siteNameError = true;
      formInput.siteNameValue = input.value;
    } else {
      formInput[input.name] = input.value;
      formInput.siteNameData = input.value;
      formInput.siteNameValue = input.value;
      formInput.siteNameError = false;
    }

    this.setState({ formInput });
  };

  handleCancelClick = () => {
    let { groupId } = this.props.match.params;
    const userData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const formInput = { ...this.state.formInput };
    formInput.siteNameData = userData[0].label;
    this.setState({ formInput });
  };

  handleToRenameSiteNameClick = () => {
    this.props.clearProjectNameData();
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const userData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    formInput.siteNameData =
      formInput.siteNameData === ""
        ? userData[0].label
        : formInput.siteNameData;

    if (formInput.siteNameValue.length < 60) {
      formInput.isButtonDisabled = true;
      formInput.newSiteName = true;
      this.setState({ formInput });
      this.props.clearNoInternet();
      this.props.clearProjectNameData();
      this.props.renameSiteName(
        formInput.siteNameData,
        groupId,
        this.props.renameSiteNameChanged
      );
    }
  };

  handleToCloseAlert = () => {
    const formInput = { ...this.state.formInput };
    formInput.newSiteName = false;
    this.setState({ formInput });
  };

  handleToCloseNoInternet = () => {
    const formInput = { ...this.state.formInput };
    formInput.noInternetView = false;
    this.setState({ formInput });
  };

  render() {
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });
    const userData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );

    formInput.siteNameData =
      formInput.siteNameData === ""
        ? userData[0].label
        : formInput.siteNameData;

    formInput.isButtonDisabled =
      this.props.renameSiteNameChanged !== undefined
        ? false
        : formInput.isButtonDisabled;
    return (
      <Fragment>
        <div className="siteView">
          {this.props.noInternet !== undefined && formInput.noInternetView && (
            <div className="alertLastSeenDialog">
              {" "}
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.noInternet !== undefined
                    ? "success-dialog"
                    : "error-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.noInternet !== undefined ? "#FF9A00" : "#FF5D5D",
                }}
                severity={
                  this.props.noInternet !== undefined ? "success" : "error"
                }
                onClose={this.handleToCloseNoInternet}
                iconMapping={{
                  success: <ReportProblemOutlinedIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                    color: "#272729",
                  }}
                >
                  {this.props.noInternet !== undefined
                    ? "No Internet"
                    : "Update Error"}
                </AlertTitle>
                {this.props.noInternet !== undefined
                  ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                  : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
              </BlackOutlinedErrorAlert>
            </div>
          )}
          {this.props.noInternet !== undefined && formInput.noInternetView && (
            <div className="row">
              <div className="alert-last-seen-dialog">
                <BlackOutlinedErrorAlert
                  className={`${
                    this.props.noInternet !== undefined
                      ? "success-dialog"
                      : "error-dialog"
                  }`}
                  style={{
                    backgroundColor:
                      this.props.noInternet !== undefined
                        ? "#FF9A00"
                        : "#FF5D5D",
                  }}
                  severity={
                    this.props.noInternet !== undefined ? "success" : "error"
                  }
                  onClose={this.handleToCloseNoInternet}
                  iconMapping={{
                    success: <ReportProblemOutlinedIcon fontSize="inherit" />,
                  }}
                >
                  <AlertTitle className="alert-title">
                    {this.props.noInternet !== undefined
                      ? "No Internet"
                      : "Update Error"}
                  </AlertTitle>
                  <span className="alert-des">
                    {this.props.noInternet !== undefined
                      ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                      : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
                  </span>
                </BlackOutlinedErrorAlert>
              </div>
            </div>
          )}
          <div className="siteInfo">
            <div className="siteName">Site Info.</div>

            <div className="siteTab">
              <div className="col-lg-1">
                <div className="siteNameView">Site Name</div>
              </div>
              <div className="col-lg-7">
                <input
                  name="name"
                  type="text"
                  value={formInput?.siteNameData}
                  onChange={this.handleSiteNameChange}
                  className="siteInput"
                />
                {formInput.siteNameError && (
                  <div className="siteErrorName">
                    Site Name cann't be more than 60 characters
                  </div>
                )}
              </div>
              <div className="col-lg-2">
                <button
                  disabled={formInput.isButtonDisabled}
                  onClick={this.handleToRenameSiteNameClick}
                  className={`submitClick ${
                    formInput.isButtonDisabled ? "spinning" : ""
                  }`}
                  style={{ marginLeft: "25px" }}
                >
                  Submit
                  {formInput.isButtonDisabled && (
                    <FontAwesomeIcon icon={faSpinner} spinPulse />
                  )}
                </button>
              </div>
              <div className="col-lg-2">
                <button
                  className="cancelClick"
                  onClick={this.handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="scroll-top-bar">
          <div className="scroll-container">
          {this.props.projectMemberDetails !== undefined ? (
            <div className="MobileView">
              <div className="siteName">Site Info.</div>
              <div className="moblieInfo">
                <div className="row pb-4">
                  <div className="col-4">
                    <div className="siteNameView">Site Name</div>
                  </div>
                  <div className="col-8">
                    <input
                      name="name"
                      type="text"
                      value={formInput?.siteNameData}
                      onChange={this.handleSiteNameChange}
                      className="siteInput"
                    />
                    {formInput.siteNameError && (
                      <div className="siteErrorName">
                        Site Name cann't be more than 60 characters
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button
                      disabled={formInput.isButtonDisabled}
                      onClick={this.handleToRenameSiteNameClick}
                      className={`submitClick ${
                        formInput.isButtonDisabled ? "spinning" : ""
                      }`}
                    >
                      Submit
                      {formInput.isButtonDisabled && (
                        <FontAwesomeIcon icon={faSpinner} spinPulse />
                      )}
                    </button>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <button
                      className="cancelClick"
                      onClick={this.handleCancelClick}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              <div className="mobileViewMember pt-3">
                <div>
                  {this.props.projectMemberDetails !== undefined ? (
                    <UserMember
                      projectMemberDetails={this.props.projectMemberDetails}
                    />
                  ) : (
                    <LoaderRoller />
                  )}
                </div>
              </div>
            </div>
            ) : (
              <LoaderRoller />
            )}
          </div>
        </div>

        <div className="siteViewMember" style={{ paddingBottom: "15px" }}>
          <div className="siteMemberView" style={{ borderRadius: "10px" }}>
            <div>
              {this.props.projectMemberDetails !== undefined ? (
                <UserMember
                  projectMemberDetails={this.props.projectMemberDetails}
                />
              ) : (
                <LoaderRoller />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <div className="alertDialog">
              {formInput.newSiteName &&
                this.props.renameSiteNameChanged !== undefined && (
                  <BlackOutlinedErrorAlert
                    className={`${
                      this.props.renameSiteNameChanged?.status === 200
                        ? "success-name-dialog"
                        : "error-name-dialog"
                    }`}
                    style={{
                      backgroundColor:
                        this.props.renameSiteNameChanged?.status === 200
                          ? "#84CE66"
                          : "#FF5D5D",
                    }}
                    severity={
                      this.props.renameSiteNameChanged?.status === 200
                        ? "success"
                        : "error"
                    }
                    onClose={this.handleToCloseAlert}
                    iconMapping={{
                      success: <CheckCircleOutlineIcon fontSize="inherit" />,
                    }}
                  >
                    <AlertTitle
                      style={{
                        fontFamily: "'Roboto Flex', sans-serif",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#272729",
                      }}
                    >
                      {this.props.renameSiteNameChanged?.status === 200
                        ? "Site Name Changed"
                        : "Site Name failed"}
                    </AlertTitle>
                    {this.props.renameSiteNameChanged?.status === 200
                      ? "Site Name Changed to " + formInput.siteNameData
                      : "Site name failed to change"}
                  </BlackOutlinedErrorAlert>
                )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    projectMemberDetails: state.listViewReducer?.projectMemberDetails,
    renameSiteNameChanged: state.listViewReducer?.renameSiteNameChanged,
    noInternet: state.listViewReducer?.noInternet,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getMemberDetailsForSite,
    renameSiteName,
    clearProjectNameData,
    clearProjectMemberData,
    clearNoInternet,
  })(user)
);

import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class EnergyChartView extends Component {
  render() {
    const { data, allChartData } = this.props;
    if (!data || !allChartData?.items) {
      return null;
    }
    const series = [];
    const dataView = [];
    const colors = {
      ActiveImportEnergy: "#267EE3",
      ActiveExportEnergy: "#272729",
      EnergyPrice: "#1FBA8E",
    };
    const types = {
      ActiveImportEnergy: "column",
      ActiveExportEnergy: "column",
      EnergyPrice: "line",
    };
    const isMobile = window.innerWidth < 768;

    data.forEach(({ data_category, datasets, unit }) => {
      const filteredData = allChartData.items.filter((item) =>
        datasets.includes(item.id_container_data_latest)
      );

      const isActiveImportEnergy = data_category === "ActiveImportEnergy";

      const color = colors[data_category];
      const type = types[data_category];
      const seriesData = filteredData.map((item) => {
        if (data_category === "EnergyPrice") {
          return item.avg_val;
        } else {
          return isActiveImportEnergy
            ? item.avg_val * -1
            : item.avg_val * item.count_val;
        }
      });

      series.push({
        name: data_category,
        color: color,
        type: type,
        data: seriesData,
        timestamp_seconds: filteredData.map(
          (item) => item.timestamp_seconds * 1000
        ),
        unit: unit,
      });
      dataView.push(filteredData);
    });

    const seriesNameMapping = {
      ActiveImportEnergy: isMobile ? "Import" : "Import",
      ActiveExportEnergy: isMobile ? "Export" : "Export",
      EnergyPrice: isMobile ? "Price" : "Price",
    };

    let maxSeriesLength = Math.max(
      series[0]?.timestamp_seconds?.length || 0,
      series[1]?.timestamp_seconds?.length || 0,
      series[2]?.timestamp_seconds?.length || 0
    );

    let lastTimestamp =
      series[0]?.timestamp_seconds[maxSeriesLength - 1] ||
      series[1]?.timestamp_seconds[maxSeriesLength - 1] ||
      series[2]?.timestamp_seconds[maxSeriesLength - 1];

    let lastHour = new Date(lastTimestamp).getHours();
    let newTimestamps = series[0]?.timestamp_seconds.slice() || [];

    for (let i = lastHour + 1; i < 24; i++) {
      let nextTimestamp = new Date();
      nextTimestamp.setHours(i, 0, 0, 0);
      newTimestamps.push(nextTimestamp.getTime());
    }

    const options = {
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="custom-tooltip">' +
            "<span>" +
            w.config.series[seriesIndex].unit +
            " " +
            (series[seriesIndex][dataPointIndex] !== undefined
              ? Number(series[seriesIndex][dataPointIndex]) % 1 !== 0
                ? Number(series[seriesIndex][dataPointIndex]).toFixed(4)
                : Number(series[seriesIndex][dataPointIndex]).toFixed(0)
              : "N/A") +
            "</span>" +
            "</div>"
          );
        },
      },
      colors: series?.map((serie) => serie?.color),
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        type: "line",
        height: 300,
      },
      noData: {
        fontSize: "16px",
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
      },
      legend: {
        show: true,
        fontFamily: "Roboto Flex",
        fontSize: "14px",
        fontWeight: "100",
        height: 30,
        labels: {
          colors: series.map((serie) => serie.color), // Use series colors for legend labels
          formatter: function (seriesName) {
            return `<span class="legend-item">${seriesName}</span>`; // Customize legend label format
          },
        },
        markers: {
          width: 0,
          height: 0,
          radius: 0,
        },
        formatter: (seriesName) => {
          const color = colors[seriesName];
          const col = "#fff";
          const displayName = seriesNameMapping[seriesName] || seriesName;
          return `<span class="legend-item" style="background-color: ${color};color: ${col};">${displayName}</span>`;
        },
      },
      stroke: {
        curve: "stepline",
      },
      dataLabels: {
        enabled: false,
      },
      title: {
        text: isMobile
          ? "Energy Price, Import, Export"
          : "Energy Price, Import and Export",
        align: "left",
        offsetX: 0,
        offsetY: -4,
        style: {
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: isMobile ? "550" : "700",
          fontFamily: "Roboto Flex, sans-serif",
        },
      },
      markers: {
        hover: {
          sizeOffset: 4,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: 13,
          horizontal: false,
          endingShape: "rounded",
          borderRadius: 1,
        },
      },
      xaxis: {
        tooltip: {
          enabled: false,
        },
        categories: newTimestamps || [],
        type: "datetime",
        labels: {
          datetimeUTC: false,
          showDuplicates: false,
        },
      },
      yaxis: [
        {
          show: true,
          seriesName: ["ActiveImportEnergy", "ActiveExportEnergy"],
          labels: {
            formatter: (value) => {
              return Number.isInteger(value)
                ? value?.toFixed(0)
                : value?.toFixed(2);
            },
          },
          title: {
            text: "kWh",
            rotate: -90,
            offsetX: isMobile ? 10 : 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
        {
          seriesName: "EnergyPrice",
          opposite: true,
          title: {
            text: "€",
            rotate: -90,
            offsetX: isMobile ? -10 : 0,
            offsetY: 0,
            style: {
              color: undefined,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              cssClass: "apexcharts-yaxis-title",
            },
          },
          labels: {
            formatter: function (value) {
              return value?.toFixed(2);
            },
          },
        },
      ],
    };
    return (
      <div>
        {dataView.length === 3 &&
        dataView.every(
          (array) => !array.includes(0) && !array.includes(null)
        ) ? (
          <ReactApexChart
            options={options}
            series={series}
            type="line"
            height={300}
            width="100%"
          />
        ) : (
          <div>No data available</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allChartData: state.listViewReducer?.allChartData,
});

export default withRouter(connect(mapStateToProps)(EnergyChartView));

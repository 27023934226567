import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./styles.css";

class ventilation extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount = () => {};
  render() {
    return (
      <Fragment>
        {" "}
        <div>hello ven</div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps, {})(ventilation));

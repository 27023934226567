import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAllChartData, clearNoInternet } from "../../actions/list-view";
import { getAllProject } from "../../actions/list-view";
import Homechart from "../chart/homechart";
import EquipmentStatus from "../chart/equipmentStatus";
import LastSeenStatus from "../chart/lastSeenStatus";
import "./styles.css";
import Homechartfilter from "../chart/homechartfilter";
import EnergyChartView from "../chart/EnergyChartView";
import LoaderRoller from "../../components/loader/LoaderRoller";
import TinySpinner from "../../components/loader/TinySpinner";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { styled } from "@mui/material/styles";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownToBracket,
  faArrowsRotate,
} from "@fortawesome/pro-solid-svg-icons";
import { faCalendar } from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import "jspdf-autotable";
// import { saveAs } from "file-saver";
// import * as XLSX from "xlsx";

class Home extends Component {
  constructor(props) {
    super(props);
    this.powerChartRef = React.createRef();
  }
  state = {
    projectData: [],
    formInput: {
      equipmentStatusData: [],
      dataSetsArray: [],
      chartName: [],
      chartUnit: [],
      filterOptions: [],
      energyData: [],
      filterLoader: false,
      noInternetView: true,
      selectedExportOption: null,
      combinedDatasets: [],
      fromDate: Math.round(new Date().getTime() / 1000),
      toDate: Math.round(new Date().getTime() / 1000),
      powerSeries: [],
    },
    isMobileFilterPopupVisible: false,
  };

  handleFilterData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        ...data,
      },
    }));
  };

  getSeriesData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        powerSeries: { ...data },
      },
    }));
  };

  componentDidMount = () => {
    const devUrls = [
      "https://evalan-dev-wattmaestro-frontend.azurewebsites.net/",
    ];
    const currentUrl = window.location.href;
    if (devUrls.some((devUrl) => currentUrl.startsWith(devUrl))) {
      document.title = "🚧 WattMaestro - Dashboard";
    } else {
      document.title = "WattMaestro - Dashboard";
    }

    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    let dataView = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    let dataSet = [];
    let dataName = [];
    let dataUnit = [];
    let energyData = [];
    let combinedDatasets = [];
    let dataviewList = dataView[0].latestSummary;
    var date = new Date();

    let start = date.setHours(0, 0, 0, 0);
    let startdate = start / 1000;

    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    let enddate = endDateMLSec / 1000;
    const currentTime = new Date().getTime();
    formInput.fromDate = startdate;
    formInput.toDate = Math.round(currentTime / 1000);
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": startdate,
      "filter[timestamp_seconds][lte]": enddate,
    };

    Object.keys(dataviewList)
      .sort((keyA, keyB) => {
        const categoryA = dataviewList[keyA].data_category;
        const categoryB = dataviewList[keyB].data_category;
        if (categoryA === "SplitEnergyMeterPowerNegative") return -1;
        if (categoryB === "SplitEnergyMeterPowerNegative") return 1;
        return 0;
      })
      .forEach((key) => {
        const dataCategory = dataviewList[key].data_category;
        if (
          dataCategory === "SplitEnergyMeterPowerNegative" ||
          dataCategory === "SplitEnergyMeterPowerPositive"
        ) {
          const datasetName = dataviewList[key].datasets;
          const chartName = dataviewList[key].data_category;
          const chatUnit = dataviewList[key].unit;
          dataSet.push(datasetName);
          dataName.push(chartName);
          dataUnit.push(chatUnit);
          combinedDatasets = combinedDatasets.concat(datasetName);
        }
        if (
          dataCategory === "ActiveImportEnergy" ||
          dataCategory === "ActiveExportEnergy" ||
          dataCategory === "EnergyPrice"
        ) {
          energyData.push(dataviewList[key]);
          const datasetName = dataviewList[key].datasets;

          combinedDatasets = combinedDatasets.concat(datasetName);
        }
      });

    combinedDatasets.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });

    formInput.combinedDatasets = combinedDatasets;

    dataSet.forEach((m, index) => {
      formInput.dataSetsArray[index] = m[0];
    });
    dataName.forEach((p, index) => {
      formInput.chartName[index] = p;
    });
    dataUnit.forEach((n, index) => {
      formInput.chartUnit[index] = n;
    });
    formInput.energyData = energyData;

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");

    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.clearNoInternet();
    this.props.getAllChartData(params);
  };

  calculateTimeAgo = (lastDataTimestamp) => {
    if (isNaN(lastDataTimestamp)) {
      return "Invalid date";
    }

    const currentTimestamp = Date.now();
    const duration = moment.duration(currentTimestamp - lastDataTimestamp);

    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths());
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds());

    if (years > 0) {
      return `${years} ${years === 1 ? "YEAR" : "YEARS"} AGO`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "MONTH" : "MONTHS"} AGO`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? "DAY" : "DAYS"} AGO`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? "HR" : "HRS"} AGO`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "MIN" : "MINS"} AGO`;
    } else {
      return `${seconds} ${seconds === 1 ? "SEC" : "SEC"} AGO`;
    }
  };
  handleToCloseNoInternet = () => {
    const formInput = { ...this.state.formInput };
    formInput.noInternetView = false;
    this.setState({ formInput });
  };

  handleToExport = (e) => {
    const selectedExport = e.target.getAttribute("value");
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          selectedExportOption: selectedExport,
        },
        formInput: { ...prevState.formInput },
      }),
      () => {
        this.handleExportSubmit(selectedExport);
      }
    );
  };

  handleExportSubmit = async (selectedExport) => {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const series = [];
    const dataView = [];
    const { powerSeries } = this.state.formInput;
    const projectFinddetails = this.props.allProjectData
      ? this.props.allProjectData.items.filter((h) => h.id_group === groupId)
      : "";

    formInput.energyData.forEach(({ data_category, datasets, unit }) => {
      const filteredData = this.props.allChartData.items.filter((item) =>
        datasets.includes(item.id_container_data_latest)
      );

      const isActiveImportEnergy = data_category === "ActiveImportEnergy";
      const seriesData = filteredData.map((item) => {
        if (data_category === "EnergyPrice") {
          return item.avg_val;
        } else {
          return isActiveImportEnergy
            ? item.avg_val * -1
            : item.avg_val * item.count_val;
        }
      });

      series.push({
        name: data_category,
        data: seriesData,
        timestamp_seconds: filteredData.map(
          (item) => item.timestamp_seconds * 1000
        ),
        unit: unit,
      });
      dataView.push(filteredData);
    });

    // FileName to be saved
    const formattedDate = new Date()
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, "");
    const fileName = `${formattedDate}_${projectFinddetails[0]?.label}`;

    // Name of the site
    const siteName = `${projectFinddetails[0]?.label}`;
    const MS_PER_HOUR = 60 * 60 * 1000;
    const MAX_HOURS = 72;

    // to convert the unix timestamp to date format
    const formatDate = (timestamp, includeTime = false) => {
      if (!timestamp) return "";
      const date = new Date(timestamp);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = "00";
      return includeTime
        ? `${day}-${month}-${year} ${hours}:${minutes}`
        : `${day}-${month}-${year}`;
    };

    const currentDate = new Date();
    const exportDate = formatDate(currentDate.getTime());

    const { fromDate, toDate } = this.state.formInput;
    const formattedFromDate = formatDate(fromDate * 1000);
    const formattedToDate = formatDate(toDate * 1000);

    const fromDateMs = fromDate * 1000;
    const toDateMs = toDate * 1000;
    const currentDateMs = new Date().getTime();
    const timeDifference = toDateMs - fromDateMs;
    const isHourly = timeDifference <= MAX_HOURS * MS_PER_HOUR;
    const stepSizePower = isHourly ? MS_PER_HOUR : 24 * MS_PER_HOUR;

    if (selectedExport === "PDF") {
      const doc = new jsPDF();
      doc.setFontSize(14);

      doc.setFont("helvetica", "bold");
      doc.text(siteName, 105, 30, null, null, "center");
      doc.setFont("helvetica", "normal");
      doc.setFontSize(12);
      doc.text(`Export date: ${exportDate}`, 105, 40, null, null, "center");

      doc.text(
        `Start Date: ${formattedFromDate}    End Date: ${formattedToDate}`,
        105,
        50,
        null,
        null,
        "center"
      );

      // Set the main title
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      const title = "Energy Price, Import and Export";
      doc.text(title, 105, 60, null, null, "center");

      // Underline the title
      const textWidth = doc.getTextWidth(title);
      const x = 105;
      const y = 60;
      const lineHeight = 5;
      doc.line(x - textWidth / 2, y + 2, x + textWidth / 2, y + 2);

      let yPosition = 70;

      // Add EnergyChartView to PDF
      if (this.energyChartRef) {
        const canvas = await html2canvas(this.energyChartRef);
        const imgData = canvas.toDataURL("image/png");
        doc.addImage(imgData, "PNG", 10, yPosition, 190, 100);
        yPosition += 110;
      }

      if (
        dataView.length === 3 &&
        dataView.every(
          (array) =>
            array.length > 0 && !array.includes(0) && !array.includes(null)
        )
      ) {
        // Create table data and sums
        const tableData = [];
        let totalEnergyImport = 0;
        let totalEnergyExport = 0;
        const dayCount = this.calculateDateDifference(fromDate, toDate);

        // Collect all timestamps from series
        const allTimestamps = [];
        series.forEach(({ timestamp_seconds }) => {
          allTimestamps.push(...timestamp_seconds);
        });

        // Create a map for quick lookup
        const dataMap = {};
        series.forEach(({ name, timestamp_seconds, data }) => {
          timestamp_seconds.forEach((ts, index) => {
            const date = formatDate(ts, isHourly);
            if (!dataMap[date]) {
              dataMap[date] = {
                date,
                energyImport: 0.0,
                energyExport: 0.0,
              };
            }
            if (name === "ActiveImportEnergy") {
              dataMap[date].energyImport = data[index].toFixed(4);
            } else {
              dataMap[date].energyExport = data[index].toFixed(4);
            }
          });
        });

        const stepSize = isHourly ? MS_PER_HOUR : 24 * MS_PER_HOUR;

        for (let i = fromDateMs; i <= toDateMs; i += stepSize) {
          const formattedCurrentDate = formatDate(i, isHourly);
          const row = dataMap[formattedCurrentDate] || {
            date: formattedCurrentDate,
            energyImport: "0.0000",
            energyExport: "0.0000",
          };

          tableData.push(row);

          // Update totals
          totalEnergyImport += parseFloat(row.energyImport);
          totalEnergyExport += parseFloat(row.energyExport);
        }

        // Add total row
        tableData.push({
          date: "Total",
          energyImport: totalEnergyImport.toFixed(4),
          energyExport: totalEnergyExport.toFixed(4),
        });

        // Add table to PDF
        doc.autoTable({
          startY: yPosition,
          head: [["Date", "Energy Import (kWh)", "Energy Export (kWh)"]],
          body: tableData.map((row, index) => [
            {
              content: row.date,
              styles:
                index === tableData.length - 1 ? { fontStyle: "bold" } : {},
            },
            {
              content: Math.abs(row.energyImport),
              styles:
                index === tableData.length - 1 ? { fontStyle: "bold" } : {},
            },
            {
              content: row.energyExport,
              styles:
                index === tableData.length - 1 ? { fontStyle: "bold" } : {},
            },
          ]),
          theme: "striped",
          styles: { valign: "middle" },
          headStyles: {
            fontSize: 12,
            fontStyle: "bold",
            fillColor: [48, 48, 48],
          },
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.75,
          margin: { top: 10 },
        });
      } else {
        doc.text("No data available", 105, yPosition, null, null, "center");
      }

      // Add Power Drawn Page
      doc.addPage();
      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Power Drawn", 105, 20, null, null, "center");

      // Underline the title
      const title_PowerConsumption = "Power Drawn";
      const textWidth_PowerConsumption = doc.getTextWidth(
        title_PowerConsumption
      );
      const x1 = 105;
      const y1 = 20;
      const lineHeight_PowerConsumption = 2;

      // Draw the underline
      doc.line(
        x1 - textWidth_PowerConsumption / 2,
        y1 + lineHeight_PowerConsumption + 2,
        x1 + textWidth_PowerConsumption / 2,
        y1 + lineHeight_PowerConsumption + 2
      );

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      // Add Power Drawn to PDF
      if (this.powerChartRef?.current) {
        const chartRefs =
          this.powerChartRef?.current.querySelectorAll(".power_drawn");
        for (let i = 0; i < chartRefs?.length; i++) {
          const chart = chartRefs[i];
          if (chart) {
            const canvas = await html2canvas(chart);
            const imgData = canvas.toDataURL("image/png");
            doc.addImage(imgData, "PNG", 10, 30, 190, 100);
          }
        }
      }

      // Add Table to Power Drawn in PDF
      let powerConsumptionData = [];
      let totalPowerConsumption = 0;

      if (formInput.powerSeries) {
        // Iterate over the powerSeries object
        Object.values(formInput.powerSeries).forEach((seriesArray) => {
          seriesArray.forEach(({ name, data, labels }) => {
            if (name === "Power Drawn") {
              powerConsumptionData = labels.map((label, index) => ({
                date: formatDate(label, isHourly),
                consumption: data[index].toFixed(4),
              }));
              totalPowerConsumption = powerConsumptionData.reduce(
                (sum, item) => sum + parseFloat(item.consumption),
                0
              );
            }
          });
        });
      }

      if (powerConsumptionData.length > 0) {
        let powerTableData = [];
        for (let i = fromDateMs; i <= toDateMs; i += stepSizePower) {
          const formattedCurrentDate = formatDate(i, isHourly);
          const row = powerConsumptionData.find(
            (d) => d.date === formattedCurrentDate
          ) || {
            date: formattedCurrentDate,
            consumption: "0.0000",
          };
          powerTableData.push({
            date: row.date,
            consumption: row.consumption,
          });
        }

        powerTableData.push({
          date: "Total",
          consumption: totalPowerConsumption.toFixed(4),
        });

        // Add the table to the PDF
        doc.autoTable({
          startY: 140,
          head: [["Date", "Power Consumption (kW)"]],
          body: powerTableData.map((row, index) => [
            {
              content: row.date,
              styles:
                index === powerTableData.length - 1
                  ? { fontStyle: "bold" }
                  : {},
            },
            {
              content: row.consumption,
              styles:
                index === powerTableData.length - 1
                  ? { fontStyle: "bold" }
                  : {},
            },
          ]),
          theme: "striped",
          styles: { valign: "middle" },
          headStyles: {
            fontSize: 12,
            fontStyle: "bold",
            fillColor: [48, 48, 48],
          },
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.75,
          margin: { top: 10 },
        });
      } else {
        // Add "No data available" text to PDF
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("No data available", 10, 140);
      }

      // Add Power Production Page
      doc.addPage();
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text("Power Production", 105, 20, null, null, "center");

      // Underline the title
      const title_PowerPoduction = "Power Poduction";
      const textWidth_PowerPoduction = doc.getTextWidth(title_PowerPoduction);
      const x2 = 105;
      const y2 = 20;
      const lineHeight_PowerPoduction = 2;

      // Draw the underline
      doc.line(
        x2 - textWidth_PowerPoduction / 2,
        y2 + lineHeight_PowerPoduction + 2,
        x2 + textWidth_PowerPoduction / 2,
        y2 + lineHeight_PowerPoduction + 2
      );

      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      // Add Power Prodution to PDF
      if (this.powerChartRef?.current) {
        const chartProdRefs =
          this.powerChartRef?.current.querySelectorAll(".power_production");
        for (let i = 0; i < chartProdRefs?.length; i++) {
          const chart = chartProdRefs[i];
          if (chart) {
            const canvas = await html2canvas(chart);
            const imgData = canvas.toDataURL("image/png");
            doc.addImage(imgData, "PNG", 10, 30, 190, 100);
          }
        }
      }
      //Add Table to Power Production to PDF
      let powerProductionData = [];
      let totalPowerProduction = 0;

      if (formInput.powerSeries) {
        // Iterate over the powerSeries object
        Object.values(formInput.powerSeries).forEach((seriesArray) => {
          seriesArray.forEach(({ name, data, labels }) => {
            if (name === "Power Production") {
              powerProductionData = labels.map((label, index) => ({
                date: formatDate(label, isHourly),
                production: data[index].toFixed(4),
              }));
              totalPowerProduction = powerProductionData.reduce(
                (sum, item) => sum + parseFloat(item.production),
                0
              );
            }
          });
        });
      }

      if (powerProductionData.length > 0) {
        const powerProdTableData = [];

        for (let i = fromDateMs; i <= toDateMs; i += stepSizePower) {
          const formattedCurrentDate = formatDate(i, isHourly);

          const row = powerProductionData.find(
            (d) => d.date === formattedCurrentDate
          ) || {
            date: formattedCurrentDate,
            production: "0.0000",
          };

          powerProdTableData.push({
            date: row.date,
            production: row.production,
          });
        }

        powerProdTableData.push({
          date: "Total",
          production: totalPowerProduction.toFixed(4),
        });

        // Add the table to the PDF
        doc.autoTable({
          startY: 140,
          head: [["Date", "Power Production (kW)"]],
          body: powerProdTableData.map((row, index) => [
            {
              content: row.date,
              styles:
                index === powerProdTableData.length - 1
                  ? { fontStyle: "bold" }
                  : {},
            },
            {
              content: row.production,
              styles:
                index === powerProdTableData.length - 1
                  ? { fontStyle: "bold" }
                  : {},
            },
          ]),
          theme: "striped",
          styles: { valign: "middle" },
          headStyles: {
            fontSize: 12,
            fontStyle: "bold",
            fillColor: [48, 48, 48],
          },
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.75,
          margin: { top: 10 },
        });
      } else {
        // Add "No data available" text to PDF
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("No data available", 10, 140);
      }

      doc.save(`${fileName}.pdf`);
    } else if (selectedExport === "Excel") {
      const workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.aoa_to_sheet([]);

      const displayAllValues =
        dataView.length === 3 &&
        dataView.every(
          (array) =>
            array.length > 0 && !array.includes(0) && !array.includes(null)
        );

      const tableData = [];
      let totalEnergyImport = 0;
      let totalEnergyExport = 0;
      let totalPowerConsumption = 0;
      let totalPowerProduction = 0;
      const dayCount = this.calculateDateDifference(fromDate, toDate);

      const dataMap = {};
      series.forEach(({ name, timestamp_seconds, data }) => {
        timestamp_seconds.forEach((ts, index) => {
          const date = formatDate(ts, isHourly);
          if (!dataMap[date]) {
            dataMap[date] = {
              date,
              energyImport: "0.0000",
              energyExport: "0.0000",
              powerConsumption: "0.0000",
              powerProduction: "0.0000",
            };
          }
          if (name === "ActiveImportEnergy" && displayAllValues) {
            dataMap[date].energyImport = data[index].toFixed(4);
          } else if (displayAllValues) {
            dataMap[date].energyExport = data[index].toFixed(4);
          }
        });
      });

      if (formInput.powerSeries) {
        Object.values(formInput.powerSeries).forEach((seriesArray) => {
          seriesArray.forEach(({ name, data, labels }) => {
            if (name === "Power Drawn") {
              data.forEach((value, index) => {
                const date = formatDate(labels[index], isHourly);
                if (dataMap[date]) {
                  dataMap[date].powerConsumption = value.toFixed(4);
                }
              });
            } else if (name === "Power Production") {
              data.forEach((value, index) => {
                const date = formatDate(labels[index], isHourly);
                if (dataMap[date]) {
                  dataMap[date].powerProduction = value.toFixed(4);
                }
              });
            }
          });
        });
      }

      for (let i = fromDateMs; i <= toDateMs; i += stepSizePower) {
        const formattedCurrentDate = formatDate(i, isHourly);

        const row = dataMap[formattedCurrentDate] || {
          date: formattedCurrentDate,
          energyImport: "0.0000",
          energyExport: "0.0000",
          powerConsumption: "0.0000",
          powerProduction: "0.0000",
        };

        tableData.push([
          row.date,
          Math.abs(parseFloat(row.energyImport)).toFixed(4),
          row.energyExport,
          row.powerConsumption,
          row.powerProduction,
        ]);

        totalEnergyImport += parseFloat(row.energyImport);
        totalEnergyExport += parseFloat(row.energyExport);
        totalPowerConsumption += parseFloat(row.powerConsumption);
        totalPowerProduction += parseFloat(row.powerProduction);
      }

      tableData.push([
        "Total",
        Math.abs(totalEnergyImport).toFixed(4),
        totalEnergyExport.toFixed(4),
        totalPowerConsumption.toFixed(4),
        totalPowerProduction.toFixed(4),
      ]);

      worksheet = XLSX.utils.aoa_to_sheet([
        [
          "Date",
          "Energy Import (kWh)",
          "Energy Export (kWh)",
          "Power Consumption (kW)",
          "Power Production (kW)",
        ],
        ...tableData,
      ]);

      // Apply bold formatting to headers
      for (let col = 0; col < 5; col++) {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
        if (!worksheet[cellAddress]) {
          worksheet[cellAddress] = {};
        }
        worksheet[cellAddress].s = { font: { bold: true } };
      }

      // Apply bold formatting to total row
      const totalRowIndex = tableData.length + 1;
      for (let col = 0; col < 5; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: totalRowIndex,
          c: col,
        });
        if (!worksheet[cellAddress]) {
          worksheet[cellAddress] = {};
        }
        worksheet[cellAddress].s = { font: { bold: true } };
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, "Export Data");

      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }
  };

  calculateDateDifference = (fromDate, toDate) => {
    const startDate = new Date(fromDate * 1000);
    const endDate = new Date(toDate * 1000);
    const diffTime = Math.abs(endDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
  };

  filterPopup = () => {
    this.setState({ isMobileFilterPopupVisible: true }); // Show the mobile filter popup
  };

  closeFilterPopup = () => {
    this.setState({ isMobileFilterPopupVisible: false }); // Hide the mobile filter popup
  };

  render() {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const data = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    formInput.equipmentStatusData = data;
    const BlackOutlinedErrorAlert = styled(Alert)({});
    const equipmentStatusData = formInput.equipmentStatusData;
    const lastSeenVal = equipmentStatusData[0]?.meta?.last_data_at;
    const sitename = data[0]?.label;
    return (
      <div className="scrolltopbar">
        <div className="scroll-container">
          <div className="homeHandleStyle">
            <div className="site-name-heading">Dashboard {sitename}</div>
            <span className="lastseen-txt">
              Last Seen:{" "}
              <span className="text-lowercase">
                {this.calculateTimeAgo(lastSeenVal)} &nbsp;
              </span>
            </span>
            {this.props.noInternet !== undefined &&
              formInput.noInternetView && (
                <div className="alertLastSeenDialog">
                  <BlackOutlinedErrorAlert
                    className={`${
                      this.props.noInternet !== undefined
                        ? "success-dialog"
                        : "error-dialog"
                    }`}
                    style={{
                      backgroundColor:
                        this.props.noInternet !== undefined
                          ? "#FF9A00"
                          : "#FF5D5D",
                    }}
                    severity={
                      this.props.noInternet !== undefined ? "success" : "error"
                    }
                    onClose={this.handleToCloseNoInternet}
                    iconMapping={{
                      success: <ReportProblemOutlinedIcon fontSize="inherit" />,
                    }}
                  >
                    <AlertTitle
                      style={{
                        fontFamily: "'Roboto Flex', sans-serif",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#272729",
                      }}
                    >
                      {this.props.noInternet !== undefined
                        ? "No Internet"
                        : "Update Error"}
                    </AlertTitle>
                    {this.props.noInternet !== undefined
                      ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                      : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
                  </BlackOutlinedErrorAlert>
                </div>
              )}
            {this.props.noInternet !== undefined &&
              formInput.noInternetView && (
                <div className="row">
                  <div className="alert-last-seen-dialog">
                    <BlackOutlinedErrorAlert
                      className={`${
                        this.props.noInternet !== undefined
                          ? "success-dialog"
                          : "error-dialog"
                      }`}
                      style={{
                        backgroundColor:
                          this.props.noInternet !== undefined
                            ? "#FF9A00"
                            : "#FF5D5D",
                      }}
                      severity={
                        this.props.noInternet !== undefined
                          ? "success"
                          : "error"
                      }
                      onClose={this.handleToCloseNoInternet}
                      iconMapping={{
                        success: (
                          <ReportProblemOutlinedIcon fontSize="inherit" />
                        ),
                      }}
                    >
                      <AlertTitle className="alert-title">
                        {this.props.noInternet !== undefined
                          ? "No Internet"
                          : "Update Error"}
                      </AlertTitle>
                      <span className="alert-des">
                        {this.props.noInternet !== undefined
                          ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                          : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
                      </span>
                    </BlackOutlinedErrorAlert>
                  </div>
                </div>
              )}
            <div>
              <div className="filter">
                <div className="d-flex mb-3 mt-3 align-items-center">
                  <Homechartfilter
                    allChartData={this.props.allChartData}
                    dataSetsArray={formInput.combinedDatasets}
                    chartName={formInput.chartName}
                    chartUnit={formInput.chartUnit}
                    handleFilterData={this.handleFilterData}
                  />
                  <div className="dropdownAlignmentView">
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle dropdownExportStyle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                      >
                        {formInput.selectedExportOption
                          ? formInput.selectedExportOption
                          : "Export"}
                      </button>
                      <div
                        className="dropdown-menu dropdownOptionStyle"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item optionValue"
                          value="PDF"
                          onClick={this.handleToExport}
                        >
                          PDF
                        </button>
                        <button
                          className="dropdown-item optionValue"
                          value="Excel"
                          onClick={this.handleToExport}
                        >
                          Excel
                        </button>
                      </div>
                    </div>
                  </div>
                  <LastSeenStatus
                    equipmentStatusData={formInput.equipmentStatusData}
                    filterOptions={formInput.filterOptions}
                  />
                </div>
              </div>
              {this.props.allChartData !== null &&
              this.props.allChartData !== undefined ? (
                <div id="siteOverviewData">
                  {this.props.loader ? <TinySpinner /> : ""}
                  <div className="margin-bottom container-top mobile-margin">
                    <div
                      className="col-lg-7 top-chart"
                      style={{
                        position: "relative",
                      }}
                      ref={(el) => (this.energyChartRef = el)}
                    >
                      <EnergyChartView data={formInput.energyData} />
                    </div>
                    <div className="bacecard-graph-chart top-chart col-lg">
                      <EquipmentStatus
                        allProjectData={this.props.allProjectData}
                        equipmentStatusData={formInput.equipmentStatusData}
                        groupId={groupId}
                      />
                    </div>
                  </div>
                  <div ref={this.powerChartRef}>
                    <Homechart
                      allChartData={this.props.allChartData}
                      dataSetsArray={formInput.dataSetsArray}
                      chartName={formInput.chartName}
                      chartUnit={formInput.chartUnit}
                      getSeriesData={this.getSeriesData}
                      chartdata={formInput}
                    />
                  </div>
                </div>
              ) : (
                <LoaderRoller />
              )}
            </div>
            <div className="sticky-button mt-2">
              <div className="d-flex justify-content-around">
                <div className="filter-btn">
                  <FontAwesomeIcon
                    className="btn-color fa-lg"
                    icon={faArrowDownToBracket}
                  />
                </div>
                <div className="filter-btn">
                  <FontAwesomeIcon
                    className="btn-color fa-lg"
                    icon={faCalendar}
                    onClick={this.filterPopup}
                  />
                </div>
                <LastSeenStatus
                  isMobile={true}
                  equipmentStatusData={formInput.equipmentStatusData}
                  filterOptions={formInput.filterOptions}
                />
              </div>
            </div>
            {/* Mobile Filter Popup */}
            {this.state.isMobileFilterPopupVisible && (
              <div className="popup">
                <div>
                  <Homechartfilter
                    isMobile={true}
                    allChartData={this.props.allChartData}
                    dataSetsArray={formInput.combinedDatasets}
                    chartName={formInput.chartName}
                    chartUnit={formInput.chartUnit}
                    handleFilterData={this.handleFilterData}
                    closePopup={this.closeFilterPopup}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    allChartData: state.listViewReducer?.allChartData,
    lastSeenDataResponse: state.listViewReducer?.lastSeenDataResponse,
    noInternet: state.listViewReducer?.noInternet,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllChartData,
    getAllProject,
    clearNoInternet,
  })(Home)
);

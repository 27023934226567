import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "../login/style.css";
import "./styles.css";
import WattMaestroUser from "../../components/menus/top-menu/WattMaestroUser";
import {} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faBookOpen } from "@fortawesome/pro-regular-svg-icons";
import { openUserPopup, closeUserPopup } from "../../actions/screen-activities";

class Header extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  render() {
    return (
      <Fragment>
        {" "}
        <div className="loginImgDiv sticky">
          <div>
            <div class="">
              <div class="row align-items-center">
                <div class="col">
                  {" "}
                  <a href="/" className="wattTitle">
                    WattMaestro
                  </a>
                </div>{" "}
                <div class="col"></div>
                {this.props.userEmail !== undefined && (
                  <div class="col d-flex justify-content-end align-items-center">
                    <div className="user-name-watt roboto-flex-uniquifier-user d-flex">
                      <div
                        className="btn"
                        onClick={() =>
                          window.open(
                            "https://docs.bace-iot.com/wattmaestro/user-manual",
                            "_blank"
                          )
                        }
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "19px" }}
                          icon={faBookOpen}
                          className="iconBlack"
                        />
                      </div>
                      <div
                        className="btn"
                        onClick={
                          this.props.topUserPopup
                            ? this.props.closeUserPopup
                            : this.props.openUserPopup
                        }
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "19px" }}
                          icon={faUser}
                          className="iconBlack"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <WattMaestroUser
                  topPopup={this.props.topUserPopup}
                  userEmail={this.props.userEmail}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <nav class="navbar navbar-light bg-light justify-content-between">
  <a class="navbar-brand">Navbar</a>
  <form class="form-inline">
    <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search"/>
    <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
  </form>
</nav> */}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, { openUserPopup, closeUserPopup })(Header)
);

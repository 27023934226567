import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import "../login/style.css";
import "./style.css";
import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountOr,
  AccountWrap,
  AccountTitle,
} from "../shared/components/account/AccountElements";

class Failed extends Component {
  state = {
    formInput: {
      emailValue: "",
      emailValid: false,
    },
  };
  componentDidMount = () => {
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
      this.props.history.push("/register/failed");
    }
  };
  render() {
    return (
      <Fragment>
        {/* <div className="loginImgDiv">
          <div className="wattTitle">WattMaestro</div>
        </div> */}
        <div>
          <AccountWrap>
            <AccountContent>
              <AccountCard>
                <AccountHead>
                  <AccountTitle>
                    Register is not completed successfully.
                    <AccountLogo> </AccountLogo>
                  </AccountTitle>
                  <h4 className="subhead">Sign Up</h4>
                </AccountHead>

                <div>
                  <div className="register-Text">
                    Note:{"  "}Mail Id already registered or Mail Id is not
                    valid.
                  </div>
                </div>

                <AccountOr></AccountOr>
              </AccountCard>
            </AccountContent>
          </AccountWrap>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
    RegisterFailedDataResult: state.userReducer?.RegisterFailedDataResult,

    RegisterDataResult: state.userReducer?.RegisterDataResult,
  };
}

export default withRouter(connect(mapStateToProps, {})(Failed));

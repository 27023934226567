import { combineReducers } from "redux";
import userReducer from "./user";
import notificationReducer from "./notification";
import redirectReducer from "./redirect";
import screenActivitiesReducer from "./screen-activities";
import listViewReducer from "./list-view";

export default combineReducers({
  userReducer,
  notificationReducer,
  redirectReducer,
  screenActivitiesReducer,
  listViewReducer,
});

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getAllChartData } from "../../actions/list-view";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { TextField } from "@material-ui/core";

class Homechartfilter extends Component {
  state = {
    projectData: [],
    formData: {
      fromDate: Math.round(new Date().getTime() / 1000),
      toDate: Math.round(new Date().getTime() / 1000),
      selectedOption: null,
    },
    formInput: {
      dataSetsArray: [],
      todateFlag: false,
    },
    isFiltering: false,
  };

  handleFilter = () => {
    this.setState({ isFiltering: true }, () => {
      // Call the filtering function
      this.handleSubmit();
    });
  };

  handleFromDateChange = (date) => {
    const fromDate = new Date(date);
    fromDate.setHours(0, 0, 0, 0);
    let startdate = fromDate.getTime() / 1000;
    let enddate = Math.round(new Date().getTime() / 1000);
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          fromDate: startdate,
          toDate: enddate,
        },
        formInput: { ...prevState.formInput, todateFlag: true },
      }),
      () => {
        this.handleSubmit();
      }
    );
  };

  handleToDateChange = (date) => {
    const formInput = { ...this.state.formData };
    const formattedDate = new Date(date);
    formattedDate.setHours(23, 59, 59);
    const endDate = formattedDate.getTime() / 1000;
    const startdate = formattedDate.setHours(0, 0, 0, 0) / 1000;
    if (formInput.fromDate === "") {
      formInput.fromDate = startdate;
      formInput.toDate = endDate;
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            fromDate: startdate,
            toDate: endDate,
          },
          formInput: { ...prevState.formInput, todateFlag: true },
        }),
        () => {
          this.handleSubmit();
        }
      );
    } else {
      formInput.toDate = endDate;
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            toDate: endDate,
          },
          formInput: { ...prevState.formInput, todateFlag: true },
        }),
        () => {
          this.handleSubmit();
        }
      );
    }
  };

  handleSubmit = () => {
    const formInput = { ...this.state.formData };
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": formInput.fromDate,
      "filter[timestamp_seconds][lte]": formInput.toDate,
    };

    if (Array.isArray(this.props.dataSetsArray)) {
      this.props.dataSetsArray.forEach((m, index) => {
        if (m !== null && m !== undefined) {
          id_container_data_latest[
            "filter[id_container_data_latest][" + index + "]"
          ] = m;
        }
      });
    } else {
      id_container_data_latest["filter[id_container_data_latest][0]"] =
        this.props.dataSetsArray;
    }

    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");

    // Calculate the time difference
    const timeDiffInHours = (formInput.toDate - formInput.fromDate) / 3600;
    if (timeDiffInHours > 72) {
      params += "&custom_resolution=day";
    } else {
      params += "&custom_resolution=hour";
    }
    const updatedFormInput = {
      ...formInput,
      filterOptions: params,
    };

    this.setState({ formInput: updatedFormInput, isFiltering: false });
    this.props.handleFilterData(updatedFormInput);
    this.props.getAllChartData(params);
  };

  handleDropdownChange = (e) => {
    const selectedOption = e.target.getAttribute("value");
    const formInput = { ...this.state.formData };
    var date = new Date();
    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    if (selectedOption === "24hour") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 86400000) / 1000
      );
      const currentTime = new Date().getTime();
      formInput.endDate = Math.round(currentTime / 1000);
    } else if (selectedOption === "1week") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 604800000) / 1000
      );
      formInput.endDate = endDateMLSec / 1000;
    } else if (selectedOption === "1month") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 2629746000) / 1000
      );
      formInput.endDate = endDateMLSec / 1000;
    } else if (selectedOption === "3month") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 7889238000) / 1000
      );
      formInput.endDate = endDateMLSec / 1000;
    } else if (selectedOption === "1Year") {
      formInput.startDate = Math.round(
        (new Date().getTime() - 31556952000) / 1000
      );

      formInput.endDate = endDateMLSec / 1000;
    } else if (selectedOption === "select") {
      const date = new Date();
      let start = date.setHours(0, 0, 0, 0);
      formInput.startDate = start / 1000;

      const currentTime = new Date().getTime();
      formInput.endDate = Math.round(currentTime / 1000);
      // formInput.endDate = endDateMLSec / 1000;
    }
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          fromDate: formInput.startDate,
          toDate: formInput.endDate,
          selectedOption:
            selectedOption === "24hour"
              ? "Last 24hr"
              : selectedOption === "1week"
              ? "Last week"
              : selectedOption === "1month"
              ? "Last month"
              : selectedOption === "3month"
              ? "Last quarter"
              : selectedOption === "select"
              ? "Select time frame"
              : "Last year",
        },
        formInput: { ...prevState.formInput, todateFlag: true },
      }),
      () => {
        this.handleSubmit();
      }
    );
  };

  render() {
    const { selectedOption } = this.state.formData;
    const formInput = { ...this.state.formData };
    return (
      <Fragment>
        {this.props.isMobile ? (
          <div>
            <button className="close-popup" onClick={this.props.closePopup}>
              Done
            </button>
            <div style={{ paddingTop: "25px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <div className="datepicker-div">
                    <DatePicker
                      className="datepicker-border"
                      value={dayjs.unix(formInput.fromDate)}
                      maxDate={dayjs()}
                      onChange={this.handleFromDateChange}
                      slotProps={{
                        textField: {
                          InputProps: {
                            startAdornment: (
                              <CalendarTodayIcon
                                style={{ marginRight: 8, color: "gray" }}
                              />
                            ),
                          },
                        },
                      }}
                      label="Start date"
                    />
                    <DatePicker
                      label="End date"
                      className="datepicker-border"
                      maxDate={dayjs()}
                      value={dayjs.unix(formInput.toDate)}
                      onChange={(date) => {
                        this.handleToDateChange(date);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      slotProps={{
                        textField: {
                          InputProps: {
                            startAdornment: (
                              <CalendarTodayIcon
                                style={{ marginRight: 8, color: "gray" }}
                              />
                            ),
                          },
                        },
                      }}
                    />
                  </div>
                </DemoContainer>
              </LocalizationProvider>
              <div>
                <button
                  className="dropdown-toggle dropdownStyle"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  style={{ width: "100%" }}
                >
                  {selectedOption ? selectedOption : "Select time frame"}
                </button>
                <div
                  className="dropdown-menu dropdownOptionStyle"
                  aria-labelledby="dropdownMenuButton"
                >
                  <button
                    className="dropdown-item optionValue"
                    value="select"
                    onClick={this.handleDropdownChange}
                  >
                    Select time frame
                  </button>
                  <button
                    className="dropdown-item optionValue"
                    value="24hour"
                    onClick={this.handleDropdownChange}
                  >
                    Last 24hr
                  </button>
                  <button
                    className="dropdown-item optionValue"
                    value="1week"
                    onClick={this.handleDropdownChange}
                  >
                    Last week
                  </button>
                  <button
                    className="dropdown-item optionValue"
                    value="1month"
                    onClick={this.handleDropdownChange}
                  >
                    Last month
                  </button>
                  <button
                    className="dropdown-item optionValue"
                    value="3month"
                    onClick={this.handleDropdownChange}
                  >
                    Last quarter
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="div-filter-box justify-content-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <div className="datepicker-div" style={{ marginLeft: "35px" }}>
                  <DatePicker
                    className="datepicker-border"
                    label="Start date"
                    value={dayjs.unix(formInput.fromDate)}
                    maxDate={dayjs()}
                    onChange={this.handleFromDateChange}
                  />
                  <DatePicker
                    className="datepicker-border"
                    label="End date"
                    maxDate={dayjs()}
                    value={dayjs.unix(formInput.toDate)}
                    onChange={(date) => {
                      this.handleToDateChange(date);
                    }}
                  />
                </div>
              </DemoContainer>
            </LocalizationProvider>

            {/* <select
            value={selectedOption}
            onChange={this.handleDropdownChange}
            className="filter-drop-down"
          >
            <option value="">Select an option</option>
            <option value="24hour">Last 24hr</option>
            <option value="1week">Last week</option>
            <option value="1month">Last month</option>
            <option value="3month">Last quarter</option>
            <option value="1Year">Last year</option>
          </select> */}

            <div className="dropdown">
              <button
                className="dropdown-toggle dropdownStyle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                // aria-haspopup="true"
                // aria-expanded="false"
              >
                {selectedOption ? selectedOption : "Select time frame"}
              </button>
              <div
                className="dropdown-menu dropdownOptionStyle w-90"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item optionValue"
                  value="select"
                  onClick={this.handleDropdownChange}
                >
                  Select time frame
                </button>
                <button
                  className="dropdown-item optionValue"
                  value="24hour"
                  onClick={this.handleDropdownChange}
                >
                  Last 24hr
                </button>
                <button
                  className="dropdown-item optionValue"
                  value="1week"
                  onClick={this.handleDropdownChange}
                >
                  Last week
                </button>
                <button
                  className="dropdown-item optionValue"
                  value="1month"
                  onClick={this.handleDropdownChange}
                >
                  Last month
                </button>
                <button
                  className="dropdown-item optionValue"
                  value="3month"
                  onClick={this.handleDropdownChange}
                >
                  Last quarter
                </button>
                {/* <a
                className="dropdown-item optionValue"
                value="1Year"
                onClick={this.handleDropdownChange}
              >
                Last year
              </a> */}
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allChartData: state.listViewReducer?.allChartData,
  };
}

export default withRouter(
  connect(mapStateToProps, { getAllChartData })(Homechartfilter)
);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCircleExclamation,
  faCircleXmark,
  faCheck,
} from "@fortawesome/pro-regular-svg-icons";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  addNewMember,
  clearAddNewMember,
  deleteSiteMember,
  clearDeleteSiteMember,
  changeAccessTypeMember,
} from "../../actions/list-view";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

class userMember extends Component {
  state = {
    formInput: {
      siteNameData: "",
      addMember: false,
      optionChange: false,
      accessChangedAlert: false,
      email: "",
      accessType: "Owner",
      addMemberMethodCall: false,
      addOptionChangeCall: false,
      removeMember: false,
      removeMailId: "",
      removeAccessType: "",
      removeMemberMethodCall: false,
      accessTypeValue: "",
    },
  };
  componentDidMount = () => {
    this.props.clearAddNewMember();
    this.props.clearDeleteSiteMember();
  };

  handleOptionChangeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.optionChange = false;

    this.setState({ formInput });
  };

  alertClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.accessChangedAlert = false;
    this.setState({ formInput });
  };

  handleToChangeAccessMember = () => {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const status = this.props.changeAccessTypeMember(
      groupId,
      formInput.changeEmail,
      formInput.currentType === "Owner"
        ? 50
        : formInput.currentType === "Contributor"
        ? 40
        : 10
    );

    formInput.optionChange = false;
    formInput.accessChangedAlert = true;

    this.setState({
      formInput,
      status: status,
    });
  };

  handleAddMemberClick = () => {
    this.props.clearAddNewMember();
    const formInput = { ...this.state.formInput };
    formInput.addMember = true;
    formInput.email = "";
    this.setState({ formInput });
  };

  handleToAddMemberClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.addMember = false;
    formInput.addMemberMethodCall = false;
    this.setState({ formInput });
  };

  handleToAddEmail = (e) => {
    const formInput = { ...this.state.formInput };
    formInput.email = e.target.value;
    this.setState({ formInput });
  };

  handleToDeleteMemberConfirmClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.removeMember = false;
    formInput.removeMemberMethodCall = false;
    formInput.addMemberMethodCall = false;
    formInput.removeMailId = "";
    formInput.removeAccessType = "";
    formInput.email = "";
    formInput.accessType = "";
    this.setState({ formInput });
  };

  handleToRemoveMember = (value, data) => {
    this.props.clearDeleteSiteMember();
    const formInput = { ...this.state.formInput };
    formInput.removeMember = true;
    formInput.removeMailId = data?.email;
    formInput.removeAccessType = data?.type;
    this.setState({ formInput });
  };

  handleToRemoveMemberClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.removeMember = false;
    formInput.removeMailId = "";
    this.setState({ formInput });
  };

  handleToAccessTypeChange = (e) => {
    const selectedOption = e.target.getAttribute("value");
    const formInput = { ...this.state.formInput };
    formInput.accessType = selectedOption;
    this.setState({ formInput });
  };

  handleToSubmitAddMember = () => {
    this.props.clearAddNewMember();
    const formInput = { ...this.state.formInput };
    formInput.addMemberMethodCall = true;
    formInput.accessTypeValue =
      formInput.accessType === "Owner"
        ? 50
        : formInput.accessType === "Contributor"
        ? 40
        : 10;
    this.setState({ formInput });
    let { groupId } = this.props.match.params;
    this.props.addNewMember(
      groupId,
      formInput.email,
      formInput.accessTypeValue
    );
  };

  handleToSubmitRemoveMember = () => {
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    formInput.removeMemberMethodCall = true;
    this.setState({ formInput });
    this.props.deleteSiteMember(
      groupId,
      formInput.removeMailId,
      formInput.removeAccessType
    );
  };

  handleOptionChangeClick = (e) => {
    const currentType = e.target.getAttribute("data-newValue");
    const changedType = e.target.getAttribute("data-oldValue");
    const changedEmail = e.target.getAttribute("data-email");
    const formInput = { ...this.state.formInput };

    formInput.optionChange = true;
    formInput.currentType = currentType;
    formInput.changeType = changedType;
    formInput.changeEmail = changedEmail;

    this.setState({ formInput });
  };

  render() {
    const formInput = { ...this.state.formInput };
    const accessTypeMapping =
      this.props.projectMemberDetails?.data?.access.filter(
        (p) => p.email === this.props.userEmail
      );

    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });

    return (
      <Fragment>
        {formInput.removeMember &&
          this.props.deleteMemberSuccess === undefined &&
          this.props.deleteMemberFailed === undefined && (
            <Dialog
              open={formInput.removeMember ? true : false}
              onClose={this.handleToRemoveMemberClose}
              className="dialogSite"
            >
              <div onClick={this.handleToRemoveMemberClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToRemoveMemberClose}
                />
              </div>
              <div className="changeSiteModeUserHeader">Remove Member</div>
              <DialogContent>
                <div className="row">
                  <div className="col-12 changeSiteModeTitle">
                    <div className="removeTextDialog d-flex justify-content-center">
                      Do you want to remove
                    </div>
                    <div className="removeTextDialog  d-flex justify-content-center">
                      {formInput.removeMailId} ?
                    </div>
                  </div>
                  <div className="col-12 buttonSiteModeView changeSiteModeTitle">
                    <div className="col-6 d-flex justify-content-center">
                      <button
                        onClick={this.handleToSubmitRemoveMember}
                        className="remove-sitemode-click"
                      >
                        REMOVE
                      </button>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <button
                        className="cancelSiteModeClick"
                        onClick={this.handleToRemoveMemberClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          )}
        {formInput.addMember &&
          this.props.addMemberCreated === undefined &&
          this.props.addMemberFailed === undefined && (
            <Dialog
              open={formInput.addMember === true ? true : false}
              onClose={this.handleToAddMemberClose}
              className="dialogSite"
            >
              <div onClick={this.handleToAddMemberClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToAddMemberClose}
                />
              </div>
              <div className="changeSiteModeUserHeader">Add Member</div>
              <DialogContent>
                <div className="row">
                  <div className="changeSiteModeTitle">
                    <div className="col-12 pb-3">
                      <div className="addText">E-mail</div>
                      <div>
                        <input
                          name="name"
                          type="text"
                          value={formInput?.email}
                          onChange={this.handleToAddEmail}
                          className="addMemberText"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="addText">Access</div>
                      {/* <select
                        //    value={formInput.siteModeNameValue}
                        onChange={this.handleToAccessTypeChange}
                        className="filter-drop-down-sitemode"
                        defaultValue={formInput.accessType}
                      >
                        <option value="Viewer">Viewer</option>
                        <option value="Contributor">Contributor</option>
                        <option value="Owner">Owner</option>
                      </select> */}
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle filter-drop-down-sitemode"
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          defaultValue={formInput.accessType}
                        >
                          {formInput.accessType
                            ? formInput.accessType
                            : "Select an option"}
                        </button>
                        <div
                          class="dropdown-menu dropdownAddMemberOptionStyle"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            class="dropdown-item addMemberoptionValue"
                            value="Owner"
                            onClick={this.handleToAccessTypeChange}
                          >
                            {formInput.accessType === "Owner" && (
                              <div className="mobile-info-member">
                                <FontAwesomeIcon
                                  className="svg-icons-sm wifiView faCheckView"
                                  icon={faCheck}
                                />
                              </div>
                            )}
                            Owner
                          </button>
                          <button
                            class="dropdown-item addMemberoptionValue"
                            value="Contributor"
                            onClick={this.handleToAccessTypeChange}
                          >
                            {formInput.accessType === "Contributor" && (
                              <div className="mobile-info-member">
                                <FontAwesomeIcon
                                  className="svg-icons-sm wifiView faCheckView"
                                  icon={faCheck}
                                />
                              </div>
                            )}
                            Contributor
                          </button>
                          <button
                            class="dropdown-item addMemberoptionValue"
                            value="Viewer"
                            onClick={this.handleToAccessTypeChange}
                          >
                            {formInput.accessType === "Viewer" && (
                              <div className="mobile-info-member">
                                <FontAwesomeIcon
                                  className="svg-icons-sm wifiView faCheckView"
                                  icon={faCheck}
                                />
                              </div>
                            )}
                            Viewer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 buttonSiteModeView changeSiteModeTitle">
                    <div className="col-6 d-flex justify-content-center">
                      <button
                        onClick={this.handleToSubmitAddMember}
                        className="submitSiteModeClick"
                      >
                        ADD
                      </button>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                      <button
                        className="cancelSiteModeClick"
                        onClick={this.handleToAddMemberClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          )}

        {formInput.optionChange && (
          <Dialog
            open={formInput.optionChange === true}
            onClose={this.handleOptionChangeClose}
            className="dialogSite"
          >
            <div onClick={this.handleOptionChangeClose}>
              <FontAwesomeIcon
                className="svg-icons-sm wifiView wifiFailVie"
                data-toggle="tooltip"
                data-placement="top"
                icon={faTimes}
                onClick={this.handleOptionChangeClose}
              />
            </div>
              <div className="headerAccess">Change Access</div>
            <DialogContent>
              <div className="row">
                <div className="col-12 changeSiteModeTitle">
                  <div className="removeTextDialog d-flex justify-content-center">
                    Do you want to change access for
                  </div>
                  <div className="removeTextDialog d-flex justify-content-center">
                    {formInput.changeEmail} ?
                  </div>
                </div>
                <div className="col-12 buttonSiteModeView changeSiteModeTitle">
                  <div className="col-6 d-flex justify-content-center">
                    <button
                      onClick={this.handleToChangeAccessMember}
                      className="submitSiteModeClick"
                    >
                      CHANGE
                    </button>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <button
                      className="cancelSiteModeClick"
                      onClick={this.handleOptionChangeClose}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )}

        <div className="siteInfoMember">
          <div className="viewMember">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="text">Members</h4>
              <div
                className={
                  accessTypeMapping.length !== 0 &&
                  accessTypeMapping[0].type !== 50
                    ? "disabledOption"
                    : ""
                }
              >
                <button
                  onClick={this.handleAddMemberClick}
                  className={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? "disabledOption addMember"
                      : "addMember"
                  }
                  disabled={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? true
                      : false
                  }
                >
                  <FontAwesomeIcon
                    className="fa-lg"
                    icon={faPlus}
                    style={{ color: "white" }}
                  />{" "}
                  <div className="memberIcon">Add Member</div>
                </button>
              </div>
            </div>
            <div className="memberViewAlign">
              <div
                className="memberTextRow row"
                style={{ paddingBottom: "10px" }}
              >
                <div className="col-md-3 memberAlign">Member</div>
                <div className="col-md-3 alignHeader">Email Address</div>
                <div className="col-md-3 alignHeader">Access</div>
                <div className="col-md-3 memberAlign">Action</div>
              </div>
              <div className="userMemberData">
                {this.props.projectMemberDetails?.data?.access.length > 0 ? (
                  this.props.projectMemberDetails?.data?.access.map((j) => (
                    <div className="d-flex justify-content-between rowDesign">
                      <div className="col-md-3">
                        <div className="colAlign">
                          {j?.email.slice(0, j?.email.lastIndexOf("@"))}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="colAlign justifyCol">{j?.email}</div>
                      </div>{" "}
                      <div className="col-md-3">
                        <div class="dropdown">
                          <button
                            class={
                              accessTypeMapping.length !== 0 &&
                              accessTypeMapping[0].type !== 50
                                ? "dropdown-toggle dropdownUserMemberStyle disabledOption accessType"
                                : "dropdown-toggle dropdownUserMemberStyle accessType"
                            }
                            type="button"
                            id="dropdownMenuButton"
                            data-bs-toggle="dropdown"
                            disabled={
                              accessTypeMapping.length !== 0 &&
                              accessTypeMapping[0].type !== 50
                                ? true
                                : false
                            }
                            //onChange={this.handleOptionChangeClick}
                          >
                            {j.type === 50
                              ? "Owner"
                              : j.type === 40
                              ? "Contributor"
                              : "Viewer"}
                            {/* {formInput.currentType === 50 ? "Owner" : formInput.currentType === 40 ? "Contributor" : "Viewer"} */}
                          </button>
                          <div
                            class="dropdown-menu dropdownAccessOptionStyle"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <button
                              class="dropdown-item optionValue"
                              data-newValue="Owner"
                              data-email={j.email}
                              data-oldValue={
                                j.type === 50
                                  ? "Owner"
                                  : j.type === 40
                                  ? "Contributor"
                                  : "Viewer"
                              }
                              onClick={this.handleOptionChangeClick}
                            >
                              Owner
                            </button>
                            <button
                              class="dropdown-item optionValue"
                              data-newValue="Contributor"
                              data-email={j.email}
                              data-oldValue={
                                j.type === 50
                                  ? "Owner"
                                  : j.type === 40
                                  ? "Contributor"
                                  : "Viewer"
                              }
                              onClick={this.handleOptionChangeClick}
                            >
                              Contributor
                            </button>
                            <button
                              class="dropdown-item optionValue"
                              data-newValue="Viewer"
                              data-email={j.email}
                              data-oldValue={
                                j.type === 50
                                  ? "Owner"
                                  : j.type === 40
                                  ? "Contributor"
                                  : "Viewer"
                              }
                              onClick={this.handleOptionChangeClick}
                            >
                              Viewer
                            </button>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-3">
                        {accessTypeMapping.length !== 0 &&
                        accessTypeMapping[0].type !== 50 ? (
                          <div>
                            <div className="btn-cancel mx-1">
                              <div
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  className="notRemoveOption"
                                  icon={faCircleExclamation}
                                  title="Action is not possible for contributor and viewer type"
                                />{" "}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="alignItem">
                            <button
                              className="remove"
                              onClick={(event) =>
                                this.handleToRemoveMember(event, j)
                              }
                            >
                              <FontAwesomeIcon
                                className="fa-1x infoProjectIcon"
                                icon={faCircleXmark}
                              />
                              <div className="removeText">Remove</div>{" "}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div> No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-info-member">
          <div className="row pb-3">
            <div className="col-6 align-self-center">
              <div className="mobile-tex">Members</div>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <div
                className={
                  accessTypeMapping.length !== 0 &&
                  accessTypeMapping[0].type !== 50
                    ? "disabledOption"
                    : ""
                }
              >
                <button
                  onClick={this.handleAddMemberClick}
                  className={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? "disabledOption addMember"
                      : "addMember"
                  }
                  disabled={
                    accessTypeMapping.length !== 0 &&
                    accessTypeMapping[0].type !== 50
                      ? true
                      : false
                  }
                >
                  <FontAwesomeIcon
                    className="fa-lg"
                    icon={faPlus}
                    style={{ color: "white" }}
                  />
                </button>
              </div>
            </div>
          </div>

          <div className="pb-5">
            {this.props.projectMemberDetails?.data?.access.length > 0 ? (
              this.props.projectMemberDetails?.data?.access.map((j) => (
                <div className="mobile-view-member mb-3">
                  <div className="row">
                    <div className="col-6">
                      <div className="mobile-text">
                        {j?.email.slice(0, j?.email.lastIndexOf("@"))}
                      </div>
                      <div className="mobile-text-email">{j?.email}</div>
                      <div class="dropdown">
                        <button
                          class={
                            accessTypeMapping.length !== 0 &&
                            accessTypeMapping[0].type !== 50
                              ? "dropdown-toggle MobiledropdownUserMemberStyle disabledOption MoblileaccessType mb-1"
                              : "dropdown-toggle MobiledropdownUserMemberStyle MoblileaccessType mb-1"
                          }
                          type="button"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          disabled={
                            accessTypeMapping.length !== 0 &&
                            accessTypeMapping[0].type !== 50
                              ? true
                              : false
                          }
                          //onChange={this.handleOptionChangeClick}
                        >
                          {j.type === 50
                            ? "Owner"
                            : j.type === 40
                            ? "Contributor"
                            : "Viewer"}
                          {/* {formInput.currentType === 50 ? "Owner" : formInput.currentType === 40 ? "Contributor" : "Viewer"} */}
                        </button>
                        <div
                          class="dropdown-menu dropdownAccessOptionStyle"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            class="dropdown-item optionValue"
                            data-newValue="Owner"
                            data-email={j.email}
                            data-oldValue={
                              j.type === 50
                                ? "Owner"
                                : j.type === 40
                                ? "Contributor"
                                : "Viewer"
                            }
                            onClick={this.handleOptionChangeClick}
                          >
                            Owner
                          </button>
                          <button
                            class="dropdown-item optionValue"
                            data-newValue="Contributor"
                            data-email={j.email}
                            data-oldValue={
                              j.type === 50
                                ? "Owner"
                                : j.type === 40
                                ? "Contributor"
                                : "Viewer"
                            }
                            onClick={this.handleOptionChangeClick}
                          >
                            Contributor
                          </button>
                          <button
                            class="dropdown-item optionValue"
                            data-newValue="Viewer"
                            data-email={j.email}
                            data-oldValue={
                              j.type === 50
                                ? "Owner"
                                : j.type === 40
                                ? "Contributor"
                                : "Viewer"
                            }
                            onClick={this.handleOptionChangeClick}
                          >
                            Viewer
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 d-flex justify-content-end align-self-center">
                      {accessTypeMapping.length !== 0 &&
                      accessTypeMapping[0].type !== 50 ? (
                        <div>
                          <div className="btn-cancel mx-1">
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <FontAwesomeIcon
                                className="notRemoveOption"
                                icon={faCircleExclamation}
                                title="Action is not possible for contributor and viewer type"
                              />{" "}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <button
                          className="removeMobile"
                          onClick={(event) =>
                            this.handleToRemoveMember(event, j)
                          }
                        >
                          <FontAwesomeIcon
                            className="fa-1x ProjectIconMobile"
                            icon={faCircleXmark}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div> No Data Found</div>
            )}
          </div>
        </div>

        <div
          className="alertUserDialog"
          onClick={this.handleToDeleteMemberConfirmClose}
        >
          {formInput.addMemberMethodCall &&
            (this.props.addMemberCreated !== undefined ||
              this.props.addMemberFailed !== undefined) && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.addMemberCreated?.status === 200
                    ? "success-name-dialog"
                    : this.props.addMemberFailed === "422"
                    ? "error-name-dialog"
                    : "error-name-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.addMemberCreated?.status === 200
                      ? "#84CE66"
                      : this.props.addMemberFailed === "422"
                      ? "#FF5D5D"
                      : "#FF5D5D",
                }}
                severity={
                  this.props.addMemberCreated?.status === 200
                    ? "success"
                    : this.props.addMemberFailed === "422"
                    ? "error"
                    : "error"
                }
                onClose={this.handleToDeleteMemberConfirmClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {this.props.addMemberCreated?.status === 200
                    ? "Member Added"
                    : this.props.addMemberFailed === "422"
                    ? "Member cannot be Added"
                    : "Member cannot be Added"}
                </AlertTitle>
                <div style={{ width: "75%" }}>
                  {" "}
                  {formInput?.email}&nbsp;
                  {this.props.addMemberCreated?.status === 200
                    ? "is added to the site."
                    : this.props.addMemberFailed === "422"
                    ? "cannot be added"
                    : "cannot be added"}
                </div>
              </BlackOutlinedErrorAlert>
            )}

          {formInput.removeMemberMethodCall &&
            (this.props.deleteMemberSuccess !== undefined ||
              this.props.deleteMemberFailed !== undefined) && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.deleteMemberSuccess?.status === 200
                    ? "success-name-dialog"
                    : this.props.deleteMemberFailed === "422"
                    ? "error-name-dialog"
                    : "error-name-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.deleteMemberSuccess?.status === 200
                      ? "#84CE66"
                      : this.props.deleteMemberFailed === "422"
                      ? "#FF5D5D"
                      : "#FF5D5D",
                }}
                severity={
                  this.props.deleteMemberSuccess?.status === 200
                    ? "success"
                    : this.props.deleteMemberFailed === "422"
                    ? "error"
                    : "error"
                }
                onClose={this.handleToDeleteMemberConfirmClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {this.props.deleteMemberSuccess?.status === 200
                    ? "Member Removed"
                    : this.props.deleteMemberFailed === "422"
                    ? "Member cannot be Removed"
                    : "Member cannot be Removed"}
                </AlertTitle>
                <div style={{ width: "75%" }}>
                  {formInput?.removeMailId}&nbsp;
                  {this.props.deleteMemberSuccess?.status === 200
                    ? "is removed from the site."
                    : this.props.deleteMemberFailed === "422"
                    ? "cannot be removed"
                    : "cannot be removed"}
                </div>
              </BlackOutlinedErrorAlert>
            )}
        </div>
        {/* //new alert */}
        <div className="alertUserDialog">
          {formInput.accessChangedAlert &&
            this.props.changeAccessType !== undefined && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.changeAccessType === 200
                    ? "success-name-dialog"
                    : "error-name-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.changeAccessType === 200 ? "#84CE66" : "#FF5D5D",
                }}
                severity={
                  this.props.changeAccessType === 200 ? "success" : "error"
                }
                onClose={this.alertClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {this.props.changeAccessType === 200
                    ? "Access Changed"
                    : "Access Change Failed"}
                </AlertTitle>
                {this.props.changeAccessType === 200
                  ? formInput?.changeEmail +
                    " is\u00A0now\u00A0a " +
                    formInput.currentType.toLowerCase() +
                    "."
                  : "The access was not changed for the member. Please try again."}
              </BlackOutlinedErrorAlert>
            )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    userEmail: state.userReducer?.user?.email,
    addMemberCreated: state.listViewReducer?.addMemberCreated,
    addMemberFailed: state.listViewReducer?.addMemberFailed,
    deleteMemberSuccess: state.listViewReducer?.deleteMemberSuccess,
    deleteMemberFailed: state.listViewReducer?.deleteMemberFailed,
    projectMemberDetails: state.listViewReducer?.projectMemberDetails,
    changeAccessType: state.listViewReducer?.changeAccessType,
    // changeAccessTypeFailed: state.listViewReducer?.changeAccessTypeFailed,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    addNewMember,
    clearAddNewMember,
    deleteSiteMember,
    clearDeleteSiteMember,
    changeAccessTypeMember,
  })(userMember)
);
